const $ = jQuery = require('jquery');
const bootstrap = require('bootstrap/dist/js/bootstrap.bundle');
// require('@fancyapps/ui')
const Fancybox = require('@fancyapps/ui').Fancybox;



var $menu = $('.nav-wrapper'); // Replace with your menu's class or ID

$(window).on('scroll', function() {
  if ($(window).scrollTop() > 0) {
    $menu.addClass('scrolled');
  } else {
    $menu.removeClass('scrolled');
  }
});

// Mobile Menu button Functionality
$("#mobile-menu-button").click(function () {
  $(this).toggleClass('closed');
  $("#mobile-menu").slideToggle(); // Toggle the visibility of the menu
});

document.addEventListener('DOMContentLoaded', function() {
  // Get all dropdown items
  var dropdownItems = document.querySelectorAll('.has-dropdown');

  // Check if dropdownItems exist
  if (dropdownItems.length > 0) {
    // Add mouseover and mouseout event to each dropdown item
    dropdownItems.forEach(function(item) {
      item.addEventListener('mouseover', function() {
        // Add 'hovered-item' class to the hovered item
        this.classList.add('hovered-item');
      });
      item.addEventListener('mouseout', function() {
        // Remove 'hovered-item' class when the mouse leaves the item
        this.classList.remove('hovered-item');
      });
    });
  }

  // Get the main menu element
  var mainMenu = document.getElementById('main-menu');

  // Check if mainMenu exists
  if (mainMenu) {
    // Add mouseleave event to the main menu
    mainMenu.addEventListener('mouseleave', function() {
      // Find any items with the 'hovered-item' class and remove it
      var hoveredItems = mainMenu.querySelectorAll('.hovered-item');
      if (hoveredItems.length > 0) {
        hoveredItems.forEach(function(hoveredItem) {
          hoveredItem.classList.remove('hovered-item');
        });
      }
    });
  }
});


// document.querySelector('.explore-slider').addEventListener('contextmenu', function (e) {
//   e.preventDefault(); // Disables the right-click context menu on the slider
// });

var exploreSwiper = new Swiper(".explore-slider", {
  allowTouchMove: true,
  loop: true,
  autoplay: {
    delay: 0,
    disableOnInteraction: true,
  },
  speed: 15000,
  on: {
    resize: function () {
      this.autoplay.start(); // Restart autoplay on resize
    },
  },
  breakpoints: {
    375: {
      slidesPerView: 1.2,
      spaceBetween: 30,
    },
    500: {
      slidesPerView: 1.8,
      spaceBetween: 30,
    },
    675: {
      slidesPerView: 2.2,
      spaceBetween: 30,
    },
    768: {
      slidesPerView: 2.7,
      spaceBetween: 30,
    },
    991: {
      slidesPerView: 3.3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 3.7,
      spaceBetween: 30,
    },
    1400: {
      slidesPerView: 4.5,
      spaceBetween: 30,
    },
  },
});
var propertyCardSwiper = new Swiper(".property-card-carousel", {
  loop: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

var galleryThumbs = new Swiper(".main-prop-carousel-thumbs", {
  centeredSlides: false, // Disable centeredSlides for smoother transitions
  slidesPerView: 3,
  watchOverflow: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  direction: 'vertical',
  spaceBetween: 8,
  breakpoints: {
    300: {
      direction: 'horizontal',
      slidesPerView: 3,
    },
    550: {
      direction: 'horizontal',
      slidesPerView: 3,
    },
    675: {
      direction: 'horizontal',
      slidesPerView: 4,
    },
    768: {
      direction: 'vertical',
      slidesPerView: 3,
    },
  }
});

var galleryMain = new Swiper(".main-prop-carousel", {
  watchOverflow: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  preventInteractionOnTransition: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  thumbs: {
    swiper: galleryThumbs
  }
});

// Synchronization between main carousel and thumbs
galleryMain.on('slideChangeTransitionStart', function() {
  galleryThumbs.slideTo(galleryMain.realIndex); // Use realIndex for looped slides
});

galleryThumbs.on('transitionStart', function(){
  galleryMain.slideTo(galleryThumbs.realIndex); // Use realIndex for looped slides
});

Fancybox.bind('[data-fancybox="gallery"]', {
  // Your custom options for a specific gallery
  Thumbs: {
    type: 'classic',
  }
});




function fetchFavoritesAndUpdateDisplay() {
  var favorites = localStorage.getItem('favorites');
  favorites = favorites ? JSON.parse(favorites) : [];
  // Use ajax_object.ajax_url
  $.post(ajax_object.ajax_url, {
    action: 'fetch_favorites',
    post_ids: favorites
  }, function(response) {
    $('#favorite-posts').html(response);

    // Reapply the is-favorite class to dynamically loaded content
    updateFavoritesDisplay();
  }).fail(function(error) {
    console.log('Error fetching favorites:', error);
  });
}

// On page load, apply the class to all favorite items
$(document).ready(function() {
  updateFavoritesDisplay(); // Apply classes based on stored favorites
});

// Add or remove from favorites and toggle the class dynamically
$(document).on('click', '.jsAddFavorite', function() {
  var $this = $(this);
  var postId = $this.data('post-id');
  var favorites = localStorage.getItem('favorites');
  favorites = favorites ? JSON.parse(favorites) : [];

  if (favorites.includes(postId)) {
    // Remove from favorites
    favorites = favorites.filter(id => id !== postId);
    $this.removeClass('is-favorite');
  } else {
    // Add to favorites
    favorites.push(postId);
    $this.addClass('is-favorite');
  }

  // Update localStorage
  localStorage.setItem('favorites', JSON.stringify(favorites));
  updateFavoritesDisplay(); // Update the display dynamically
});

function updateFavoritesDisplay() {
  var favorites = localStorage.getItem('favorites');
  favorites = favorites ? JSON.parse(favorites) : [];
  $('.like-count span').text(favorites.length);

  // Update the class for all favorite buttons
  $('.jsAddFavorite').each(function() {
    var postId = $(this).data('post-id');
    if (favorites.includes(postId)) {
      $(this).addClass('is-favorite');
    } else {
      $(this).removeClass('is-favorite');
    }
  });
}

// Initial fetch and display
fetchFavoritesAndUpdateDisplay();



var testimonials = new Swiper(".testimonial-swiper", {
  slidesPerView: 3,
  spaceBetween: 32,
  loop: true,
  watchOverflow: false,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    300: {
      slidesPerView: 1,
    },
    550: {
      slidesPerView: 1,
    },
    675: {
      slidesPerView: 1,
    },
    991: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 3,
    },
  }
});

jQuery(document).ready(function($) {
  var $fixedButton = $('#fixed-button');
  var $footer = $('footer'); // Adjust this selector to match your footer element
  var footerTop, windowHeight, scrollTop;

  function updateButtonVisibility() {
    footerTop = $footer.offset().top;
    windowHeight = $(window).height();
    scrollTop = $(window).scrollTop();

    if (scrollTop + windowHeight > footerTop) {
      var opacity = 1 - (scrollTop + windowHeight - footerTop) / 100;
      opacity = Math.max(0, Math.min(1, opacity));
      $fixedButton.css('opacity', opacity);
    } else {
      $fixedButton.css('opacity', 1);
    }
  }

  $(window).on('scroll resize', updateButtonVisibility);
  updateButtonVisibility(); // Initial call to set button visibility
});




$(document).ready(function() {
  // Initialize Bootstrap tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  // Get the share button
  var $shareButton = $('#shareButton');

  // Initialize the tooltip instance
  var tooltip = bootstrap.Tooltip.getInstance($shareButton[0]);

  $shareButton.on('click', function(e) {
    e.preventDefault();

    // Get the current URL
    var currentURL = window.location.href;

    // Copy the URL to clipboard
    navigator.clipboard.writeText(currentURL).then(function() {
      // Change tooltip text
      $shareButton.attr('data-bs-original-title', 'Copied to clipboard!');

      // Show the tooltip
      tooltip.show();

      // Hide the tooltip after 2 seconds
      setTimeout(function() {
        tooltip.hide();
        // Reset the tooltip text
        $shareButton.attr('data-bs-original-title', 'Share Listing');
      }, 2000);
    }, function(err) {
      console.error('Could not copy text: ', err);
    });
  });
});
